(function ($) {


  const Init = (() => {

    let website = {},
      load = {},
      resize = {},
      resizeW = {},
      scroll = {},
      orientation = {},
      mod = {},
      anime = {},
      scrollOption = {},
      parallax = {},
      drawer = {},
      wp = {};
    slider = {};
    top = {};
    basic = {};

    website = {
      page: document.body.dataset.page,
      url: document.URL,
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
      scrollY: window.pageYOffset,
      scrollCurrent: 0,
      breakpoint: { pc: 1200, tb: 992, sp: 768, ss: 576 },
      ua: navigator.userAgent.toLowerCase(),
      isDesktop: true,
      isMobile: false,
      isFirst: true,
      animationFrame: null,

      headTag: document.getElementById('js-header'),
      footTag: document.getElementById('js-footer'),
      wrapperTag: document.getElementById('js-wrapper'),
      // headTag       : $('#js-header'),
      // footTag       : $('#js-footer'),

      act: 'is-act',
      actView: 'is-view',
      actOpen: 'is-open',
      loaded: 'is-loaded',

      targetAnime: 'js-anime',
      actAnime: 'is-anime',
      shiftPoint: 0,
      shiftPc: 5,
      shiftSp: 10,
    };
    website.shiftPoint = website.breakpoint.tb;

    load = {
      functions: [],
      length: 0,
    };

    resize = {
      functions: [],
      length: 0,
      // fps      : 60,
      isRunning: false,
      animationFrame: null,
    };
    resizeW = {
      functions: [],
      length: 0,
      // fps      : 60,
      isRunning: false,
      animationFrame: null,
    };

    scroll = {
      functions: [],
      length: 0,
      // fps      : 60,
      isRunning: false,
      animationFrame: null,
    };

    orientation = {
      functions: [],
      length: 0,
      // fps      : 60,
      isRunning: false,
      animationFrame: null,
    };




    




    const WinLoad = {




    




      init() {




        window.addEventListener('load', function(e) {




          WinLoad.update();




        });




    




        console.log('!-- load init --!');




      },




    




      add(func) {




        load.functions.push(func);




        load.length = load.functions.length;




      },




    




      remove(func) {




        load.functions.splice(func, 1);




        load.length = load.functions.length;




      },




    




      update() {




        for ( let i = 0; i < load.length; i++ ) {




          let func = load.functions[i];




          func();




        }




      },




    




    }




    




    




    const WinResize = {




    




      init() {




        window.addEventListener('resize', function(e) {




          if ( !resize.isRunning ) {




            resize.isRunning = true;




    




            resize.animationFrame = window.requestAnimationFrame( WinResize.update );




    




            // if ( window.requestAnimationFrame ) {




            //   window.requestAnimationFrame(() => {




            //     WinResize.update();




            //   });




            // } else {




            //   setTimeout(() => {




            //     WinResize.update();




            //   }, 1000/resize.fps);




            // }




          }




        });




    




        console.log('!-- resize init --!');




      },




    




      add(func) {




        resize.functions.push(func);




        resize.length = resize.functions.length;




      },




    




      remove(func) {




        resize.functions.splice(func, 1);




        resize.length = resize.functions.length;




      },




    




      update() {




        website.winWidth  = window.innerWidth;




        website.winHeight = window.innerHeight;




        website.ua        = navigator.userAgent.toLowerCase();




    




        for ( let i = 0; i < resize.length; i++ ) {




          let func = resize.functions[i];




          func();




        }




    




        resize.isRunning = false;




      },




    




    }




    




    




    const WinResizeWidth = {




    




      init() {




        window.addEventListener('resize', function(e) {




          if ( website.winWidth != window.innerWidth && !resizeW.isRunning ) {




            resizeW.isRunning = true;




            resizeW.animationFrame = window.requestAnimationFrame( WinResizeWidth.update );




          }




        });




    




        console.log('!-- resizeW init --!');




      },




    




      add(func) {




        resizeW.functions.push(func);




        resizeW.length = resizeW.functions.length;




      },




    




      remove(func) {




        resizeW.functions.splice(func, 1);




        resizeW.length = resizeW.functions.length;




      },




    




      update() {




        for ( let i = 0; i < resizeW.length; i++ ) {




          let func = resizeW.functions[i];




          func();




        }




    




        resizeW.isRunning = false;




      },




    




    }




    




    




    const WinScroll = {




    




      init() {




        window.addEventListener('scroll', function(e) {




          if ( !scroll.isRunning ) {




            scroll.isRunning = true;




    




            scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );




    




            // if ( window.requestAnimationFrame ) {




            //   window.requestAnimationFrame(() => {




            //     WinScroll.update();




            //   });




            // } else {




            //   setTimeout(() => {




            //     WinScroll.update();




            //   }, 1000/scroll.fps);




            // }




          }




        });




    




        console.log('!-- scroll init --!');




      },




    




      add(func) {




        scroll.functions.push(func);




        scroll.length = scroll.functions.length;




      },




    




      remove(func) {




        scroll.functions.splice(func, 1);




        scroll.length = scroll.functions.length;




      },




    




      update() {




        website.winHeight = window.innerHeight;




        website.scrollY   = window.pageYOffset;




    




        for ( let i = 0; i < scroll.length; i++ ) {




          let func = scroll.functions[i];




          func();




        }




    




        scroll.isRunning = false;




      },




    




    }




    




    




    const OrientationChange = {




    




      init() {




        window.addEventListener('orientationchange', function(e) {




          if ( !orientation.isRunning ) {




            orientation.isRunning = true;




    




            orientation.animationFrame = window.requestAnimationFrame( OrientationChange.update );




          }




        });




    




        console.log('!-- orientationchange init --!');




      },




    




      add(func) {




        orientation.functions.push(func);




        orientation.length = orientation.functions.length;




      },




    




      remove(func) {




        orientation.functions.splice(func, 1);




        orientation.length = orientation.functions.length;




      },




    




      update() {




        for ( let i = 0; i < orientation.length; i++ ) {




          let func = orientation.functions[i];




          func();




        }




    




        orientation.isRunning = false;




      },




    




    }




    

    

    const Modules = {

    

      init() {

        mod = {

          targetSmooth: 'js-scroll',

          smoothSpeed: 600,

    

          targetTab: 'js-tabWrap',

    

          targetAccordion: 'js-accordion',

          targetAccordionSp: 'js-accordionSp',

          targetAccordionClose: 'js-accordionClose',

    

          // targetModalOpen   : 'js-modal',

          // targetModalClose  : 'js-modalClose',

          // targetModalOverlay: 'js-modalOverlay',

          // actBodyModal      : 'is-modal',

    

          // targetCursor   : 'js-cursor',

          // targetCursorSub: 'js-cursorSub',

    

          // targetAlign     : 'js-align',

          // targetAlignList : [],

          // alignAddClass   : 'u-align_height',

    

          alignAddClass: 'u-align_height',

    

          targetCompany: 'js-formCompany',

          targetCompanyItem: 'js-formCompanyItem',

        };

    

        // let alignList = document.querySelectorAll('.' + mod.targetAlign);

        // mod.targetAlignList = Array.prototype.slice.call(alignList, 0);

    

        this.iosCheck();

        this.deviceCheck();

        this.smoothScroll();

        this.tabBtn();

        this.accordionBtn();

        // this.modalBtn();

        this.formCompany();

    

        objectFitImages('.' + mod.targetOfi);

      },

    

      viewportHeight() {

        let vh = website.winHeight * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);

      },

    

      iosCheck() {

        if (website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document)) {

          $('body').addClass('is-ios');

        } else {

          $('body').removeClass('is-ios');

        }

      },

    

      deviceCheck() {

        if (

          website.ua.indexOf('iphone') > -1 ||

          website.ua.indexOf('ipod') > -1 ||

          website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1

        ) {

          website.isDesktop = false;

          website.isMobile = true;

          $('body').removeClass('is-pc is-tb').addClass('is-sp');

        } else if (

          website.ua.indexOf('ipad') > -1 ||

          website.ua.indexOf('Android') > -1 ||

          (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document)

        ) {

          website.isDesktop = false;

          website.isMobile = false;

          $('body').removeClass('is-pc is-sp').addClass('is-tb');

        } else {

          website.isDesktop = true;

          website.isMobile = false;

          $('body').removeClass('is-tb is-sp').addClass('is-pc');

        }

      },

    

      smoothScroll() {

        $('.' + mod.targetSmooth + ':not([href=""])').on('click', function () {

          let href = $(this).attr("href"),

            target = $((href == "#") ? 'html' : href),

            shift = (href != "#") ? website.headTag.offsetHeight : 0,

            position = target.offset().top - shift;

    

          $('body,html').animate({ scrollTop: position }, mod.smoothSpeed, 'swing');

          return false;

        });

      },

    

      tabBtn() {

        $('.' + mod.targetTab).each(function () {

          let tabObj = $('> li > a', this),

            contId = ($(this).data('tab')) ? $(this).data('tab') : '',

            contsObj = (contId) ? $(contId).children('div') : $(this).next().children('div');

    

          tabObj.on('click', function () {

            let tabHref = $(this).attr('href');

    

            tabObj.removeClass(website.act);

            $(this).addClass(website.act);

    

            contsObj.hide();

            $(tabHref).fadeIn();

    

            return false;

          });

        });

      },

    

      accordionBtn() {

        $('.' + mod.targetAccordion).on('click', function (e) {

          if ($(this).hasClass(website.actOpen)) {

            $(this).removeClass(website.actOpen).next().stop().slideUp('fast');

            // $(this).removeClass(website.actOpen);

          } else {

            $(this).addClass(website.actOpen).next().stop().slideDown();

            // $(this).addClass(website.actOpen);

          }

        });

    

        $('.' + mod.targetAccordionSp).on('click', function (e) {

          if (website.winWidth < website.shiftPoint) {

            e.preventDefault();

            if ($(this).hasClass(website.actOpen)) {

              $(this).removeClass(website.actOpen).next().stop().slideUp('fast');

              // $(this).removeClass(website.actOpen);

            } else {

              $(this).addClass(website.actOpen).next().stop().slideDown();

              // $(this).addClass(website.actOpen);

            }

          }

        });

    

        $('.' + mod.targetAccordionClose).on('click', function (e) {

          let closeTarget = $(this).data('close');

          $(this).closest('.' + closeTarget).stop().slideUp('fast').prev().removeClass(website.actOpen);

        });

      },

    

      // modalBtn() {

      //   $(document).on('click', '.' + mod.targetModalOpen, function(e){

      //     let href = $(this).attr("href");

      //     $('body').addClass(mod.actBodyModal);

      //     $(href).addClass(website.actOpen).parent().addClass(website.act);

      //     return false;

      //   });

    

      //   $('.' + mod.targetModalClose).each(function() {

      //     $(this).on('click', function(){

      //       $('body').removeClass(mod.actBodyModal);

      //       $(this).closest('.' + website.actOpen).removeClass(website.actOpen).parent().removeClass(website.act);

      //     });

      //   });

    

      //   $(document).on('click', '.' + mod.targetModalOverlay, function(){

      //     $('body').removeClass(mod.actBodyModal);

      //     $(this).nextAll('.' + website.actOpen).removeClass(website.actOpen).parent().removeClass(website.act);

      //   });

      // },

    

      formCompany() {

        $('.' + mod.targetCompanyItem).change(function () {

          let val,

            thisName = $(this).attr('name'),

            front = $('select[name="company_status_front"]').val(),

            name = $('input[name="company_name"]').val(),

            back = $('select[name="company_status_back"]').val();

    

          if (thisName == 'company_status_front') {

            $('select[name="company_status_back"]').val('');

            val = front + name;

          } else if (thisName == 'company_status_back') {

            $('select[name="company_status_front"]').val('');

            val = name + back;

          } else {

            val = front + name + back;

          }

    

          $('#' + mod.targetCompany).val(val);

        });

        $('#' + mod.targetCompany).attr('autocomplete', 'off')

      },

    

      // cursor() {

      //   let cursor    = document.getElementById(mod.targetCursor),

      //       cursorSub = document.getElementById(mod.targetCursorSub);

    

      //   document.addEventListener('mousemove', function (e) {

      //     cursor.style.transform    = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

      //     cursorSub.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

      //   });

    

    

      //   let linkElem = document.querySelectorAll('a, button');

      //   for (let i = 0; i < linkElem.length; i++) {

      //     linkElem[i].addEventListener('mouseover', function (e) {

      //       // cursor.classList.add(website.act);

      //       cursorSub.classList.add(website.act);

      //     });

      //     linkElem[i].addEventListener('mouseout', function (e) {

      //       // cursor.classList.remove(website.act);

      //       cursorSub.classList.remove(website.act);

      //     });

      //   }

      // },

    

      // alignHeight() {

      //   let alignHeight  = 0;

    

      //   mod.targetAlignList.forEach((target) => {

      //     target.style.minHeight = 'auto';

      //     target.classList.add(mod.alignAddClass);

      //     alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;

      //     target.classList.remove(mod.alignAddClass);

      //   });

      //   mod.targetAlignList.forEach((target) => {

      //     target.style.minHeight = alignHeight + 'px';

      //   });

      // },

    

    }

    

    

    const DrawerMenu = {

    

      init() {

        drawer = {

          panel     : 'js-drawerPanel',

          menu      : 'js-drawerMenu',

          duration  : 10,

          // openclass : 'slideout-open',

          toggleList: '#js-drawerToggle',

          closeList : '#js-drawerPanel, #js-drawerMenu .js-scroll',

        };

    

        this.menu();

      },

    

      menu() {

        if ( document.getElementById(drawer.menu) != null ) {

          let slideoutInst = new Slideout({

            'panel': document.getElementById(drawer.panel),

            'menu': document.getElementById(drawer.menu),

            'duration': drawer.duration,

            // 'openclass': drawer.openclass,

          });

    

          let nodeList = document.querySelectorAll(drawer.toggleList);

          let node = Array.prototype.slice.call(nodeList,0);

          node.forEach(function(elem, index){

            elem.addEventListener('click', function() {

              slideoutInst.toggle();

            });

          });

    

          let nodeListClose = document.querySelectorAll(drawer.closeList);

          let nodeClose = Array.prototype.slice.call(nodeListClose,0);

          nodeClose.forEach(function(elem, index){

            elem.addEventListener('click', function() {

              slideoutInst.close();

            });

          });

        }

      },

    

    }

    

    

    

    const Wordpress = {

    

      init() {

        wp = {

          editorTarget: 'js-editor',

          tableClass: 'c-tbl_responsive',

          iframeclass: 'c-iframe',

        };

    

        this.wpEditor();

      },

    

      wpEditor() {

        // if ( document.getElementById(wp.editorTarget) != null ) {

        //   $( '#' + wp.editorTarget + ' table' ).each(function() {

        //     $(this).wrap('<div class="' + wp.tableClass + '"></div>');

        //   });

        //   $( '#' + wp.editorTarget + ' iframe' ).each(function() {

        //     $(this).wrap('<div class="' + wp.iframeclass + '"></div>');

        //   });

        // }

        if ($('.' + wp.editorTarget).length) {

          $('.' + wp.editorTarget + ' table').each(function () {

            $(this).wrap('<div class="' + wp.tableClass + '"></div>');

          });

          $('.' + wp.editorTarget + ' iframe').each(function () {

            $(this).wrap('<div class="' + wp.iframeclass + '"></div>');

          });

        }

      }

    

    }

    

    

    

    const Slick = {

    

      init() {

        slider = {};

      },

    

      topKey() {

        $('#js-sldKey').slick({

          fade: true,

          autoplay: true,

          autoplaySpeed: 5000,

          speed: 1200,

          cssEase: 'linear',

          arrows: false,

          dots: false,

          pauseOnFocus: false,

          pauseOnHover: false,

        });

      },

    

      topProperty() {

        $('#js-sldProperty').slick({

          autoplay: true,

          autoplaySpeed: 5000,

          speed: 1200,

          slidesToShow: 3,

          infinite: false,

          arrows: true,

          appendArrows: $('#js-sldPropertyArrow'),

          dots: true,

          appendDots: $('#js-sldPropertyDot'),

          pauseOnFocus: false,

          pauseOnHover: false,

          responsive: [

            {

              breakpoint: 992,

              settings: {

                slidesToShow: 2,

              }

            },

            // {

            //   breakpoint: 576,

            //   settings: {

            //     slidesToShow: 2,

            //   }

            // },

          ]

        });

        var a = 100 / $('.slick-dots li').length;

        $('.slick-dots li').css({

            width: a + "%"

        })

      },

    

    }

    


    // OFF

    // ./inc/_animation.js

    // ./inc/_pagescroll.js

    // ./inc/_parallax.js



    const BasicPage = {

      init() {
        basic = {
        };
      },

      update() {
      },

    }



    const TopPage = {

      init() {
        top = {};

        this.fixHeader();
        Slick.topKey();
        Slick.topProperty();
      },

      fixHeader() {
        if (website.scrollY > 100) {
          website.headTag.classList.add(website.act);
        } else {
          website.headTag.classList.remove(website.act);
        }
      },


      update() {
      },

    }


    const App = {

      init() {
        if (website.page != 'wp-manual' && website.page != 'wp-manual-single') {
          this.set();
          // this.update();
        } else {
          // Modules.init();
          Wordpress.init();
        }
      },

      set() {
        Modules.init();
        // Animation.init();
        // PageScroll.init();
        // Parallax.init();
        DrawerMenu.init();
        Wordpress.init();
        Slick.init();
        // BasicPage.init();


        if (website.page == 'top') {
          TopPage.init();
        }


        WinLoad.add(Modules.viewportHeight);
        // WinLoad.add( Animation.check );
        // WinLoad.add( Modules.alignHeight );

        WinResize.add(Modules.iosCheck);
        WinResize.add(Modules.deviceCheck);

        WinResizeWidth.add(Modules.viewportHeight);
        // WinResizeWidth.add( Modules.alignHeight );

        // WinScroll.add( Animation.check );
        WinScroll.add(TopPage.fixHeader);

        // OrientationChange.add( xxx );


        WinLoad.init();
        WinResize.init();
        WinResizeWidth.init();
        WinScroll.init();
        OrientationChange.init();

      },

      update() {
        //   website.animationFrame = window.requestAnimationFrame( App.update );
        //   website.winWidth       = window.innerWidth;
        //   website.winHeight      = window.innerHeight;
        //   website.scrollY        = window.pageYOffset;

        //   if ( website.isDesktop ) {
        //     PageScroll.smooth();

        //     if ( website.page == 'top' ) {
        //       Parallax.moveImg();
        //     }
        //   }
      },

    }


    App.init();

  })();


})(jQuery);
